import { computed, Injectable, signal } from '@angular/core';
import { Nullable } from '@models/nullable.model';

export interface DialogInfo {
  title?: string;
  icon?: string;
  confirmAction?: () => void;
  cancelAction?: () => void;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  showCancelButton?: boolean;
  message: Nullable<string | string[]>;
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private _dialogInfo = signal<Nullable<DialogInfo>>(null);

  dialogInfo = computed(() => this._dialogInfo());

  open(options: DialogInfo): void {
    this._dialogInfo.set({ ...options });
  }

  reset(): void {
    this._dialogInfo.set(null);
  }
}
